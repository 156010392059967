<template>
  <the-base-table
    ref="baseTable"
    :url="url"
    singularName="Beneficio"
    pluralName="Beneficios"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="benefitEdited"
    :objectDefault="benefitDefault"
    :showActionsColumn="false"
  >
    <template v-slot:form="{ isEditing }">
      <benefit-form
        :is-editing="isEditing"
        :benefit="benefitEdited"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>
    <template v-slot:toolbar-append>
      <v-container>
        <v-layout wrap>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="year"
              :items="years"
              :loading="loadingYear"
              v-model="filter.year"
              :value="filter.year"
              label="Año de validez"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="btypeCateogry"
              :items="btypeCategories"
              :loading="loadingBtypeCategory"
              v-model="filter.category"
              :value="filter.category"
              label="Categoria de beneficio"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td class="text-right">{{ item.amount | currency }}</td>
      <td class="text-center">{{ item.year }}</td>
      <td>{{ item.get_workday_display }}</td>
      <td>{{ item.get_btype_group_display }}</td>
      <td>{{ item.get_btype_type_display }}</td>
      <td class="text-center" v-if="item.is_active">
        <v-icon large color="green darken-2">check_circle_outline</v-icon>
      </td>
      <td class="text-center" v-if="!item.is_active">
        <v-icon large color="red darken-2">highlight_off</v-icon>
      </td>
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import BenefitForm from '@/components/benefits/BenefitForm'
import Utils from '@/utils'

export default {
  data: () => ({
    years: [],
    btypeCategories: [],
    loadingBtypeCategory: true,
    loadingYear: true,
    filter: {
      year: null,
      category: null,
      workday: null,
    },
    benefitEdited: {
      amount: '',
      year: '',
      btype: '',
      workday: '',
    },
    headers: [
      { text: 'ID', value: 'id', align: 'center' },
      { text: 'Monto ($)', value: 'amount', align: 'center' },
      { text: 'Inicio vigencia', value: 'year', align: 'center' },
      {
        text: 'Jornada Laboral',
        value: 'get_workday_display',
        align: 'center',
      },
      { text: 'Categoria', value: 'get_btype_group_display', align: 'center' },
      { text: 'Beneficio', value: 'get_btype_type_display', align: 'center' },
      { text: 'Activo', value: 'is_active', align: 'center' },
    ],
  }),
  mounted() {
    if (
      this.$store.getters['auth/user'].active_role === Utils.userRoles[1].id
    ) {
      this.filter.workday = this.$store.getters[
        'auth/user'
      ].get_affiliate_profile.workday
    }
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('benefits/years'))
        .then(data => {
          data.data.forEach(Year => {
            this.years.push({
              value: Year.year,
              text: Year.year,
            })
          })
          this.years.push({
            value: null,
            text: 'Todos',
          })
          this.loadingYear = false
        })
        .catch(error => console.log(error))
      this.axios
        .get(this.$api('btypes/categories'))
        .then(data => {
          data.data.forEach(BtypeCategory => {
            this.btypeCategories.push({
              value: BtypeCategory.id,
              text: BtypeCategory.group,
            })
          })
          this.btypeCategories.push({
            value: null,
            text: 'Todos',
          })
          this.loadingBtypeCategory = false
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    filters() {
      return this.filter
    },
    url() {
      return '/benefits'
    },
    benefitDefault() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return {
          id: -1,
          amount: '',
          year: '',
          btype: '',
          workday: '',
        }
      }
      return undefined
    },
  },
  methods: {
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    error(event) {
      console.log(event)
    },
  },
  components: {
    TheBaseTable,
    BenefitForm,
  },
}
</script>
