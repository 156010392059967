<template>
  <the-base-form
    :url="url"
    :object="benefit"
    :is-editing="isEditing"
    reset-on-open
    singularName="Beneficio"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md6>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <ValidationProvider
            name="Fecha inicio de validez"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              :value="computedDateFormatted"
              label="Fecha inicio de validez"
              hint="dd-mm-aaaa"
              persistent-hint
              prepend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </template>
        <v-date-picker
          locale="es-CL"
          v-model="benefit.year"
          no-title
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Monto de bonificación"
        rules="required"
        v-slot="{ errors }"
      >
        <vuetify-money
          v-model="benefit.amount"
          label="Monto de bonificación"
          :readonly="false"
          :outlined="false"
          v-bind:options="options"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Grupo de beneficio"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="btypeCategory"
          :items="btypeCategories"
          v-model="btypeCategory"
          :loading="loadingBtypeCategory"
          :value="btypeCategory"
          label="Grupo de Beneficio"
          :error-messages="errors"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Tipo Beneficio"
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          name="btype"
          :items="btypes"
          :loading="loadingBtype"
          v-model="benefit.btype"
          :value="benefit.btype"
          label="Tipo Beneficio"
          :error-messages="errors"
        ></v-autocomplete>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Jornada Laboral"
        rules="required"
        v-slot="{ errors }"
      >
        <v-select
          name="workday"
          :items="workdays"
          v-model="benefit.workday"
          :value="benefit.workday"
          :loading="loadingWorkday"
          label="Jornada laboral"
          :error-messages="errors"
        ></v-select>
      </ValidationProvider>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
import Utils from '@/utils'
import VuetifyMoney from '@/components/utils/VuetifyMoney'
export default {
  data: () => ({
    btypes: [],
    workdays: [],
    menu2: false,
    btypeCategories: [],
    btypeCategory: null,
    loadingBtypeCategory: true,
    loadingBtype: true,
    loadingWorkday: true,
    options: {
      locale: 'es-ES',
      prefix: 'CLP$',
      precision: 0,
    },
  }),
  components: {
    TheBaseForm,
    VuetifyMoney,
  },
  props: {
    benefit: Object,
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    url() {
      return 'benefits/'
    },
    computedDateFormatted() {
      return Utils.formatDate(this.benefit.year)
    },
  },
  watch: {
    btypeCategory(val) {
      this.btypes = []
      this.loadingBtype = true
      this.axios
        .get(this.$api('btypes/', { group: val }))
        .then(data => {
          data.data.forEach(BenefitType => {
            this.btypes.push({
              value: BenefitType.id,
              text: BenefitType.name,
            })
          })
          this.loadingBtype = false
        })
        .catch(error => console.log(error))
    },
  },
  created() {
    this.axios
      .get(this.$api('btypes/categories'))
      .then(data => {
        data.data.forEach(BtypeCategory => {
          this.btypeCategories.push({
            value: BtypeCategory.id,
            text: BtypeCategory.group,
          })
        })
        this.loadingBtypeCategory = false
      })
      .catch(error => console.log(error))
    this.axios
      .get(this.$api('workdays/'))
      .then(data => {
        data.data.forEach(WorkdayTypes => {
          this.workdays.push({
            value: WorkdayTypes.id,
            text: WorkdayTypes.workday,
          })
        })
        this.loadingWorkday = false
      })
      .catch(error => console.log(error))
  },
  methods: {},
}
</script>
