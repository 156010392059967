<template>
  <div id="benefitIndex">
    <benefit-table />
  </div>
</template>

<script>
import BenefitTable from '@/components/benefits/BenefitTable'
export default {
  components: { BenefitTable },
  data () {
    return {}
  },
  computed: {
  }
}
</script>
